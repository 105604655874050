import get from 'lodash/get';
import * as cohorts from '../constants/cohorts';
import {
  LIVE,
  NOADS_HULU_DISNEY_ESPN,
  NOAH,
  ADS_LIVE_DISNEY_ESPN,
  ADS_HULU_DISNEY_ESPN,
  PSEUDO_2P,
  ADS_MAX_BUNDLE,
  NOADS_MAX_BUNDLE,
  DUO_BASIC,
  DUO_PREMIUM,
} from '../constants/plans';
import { BADGE_TYPES } from '../constants/badge';
import { LIVE_CALLOUT } from '../constants/planText';

/**
 * Checks if plan includes live component
 * @returns {boolean} True if plan has includesLive property set to true
 */
export function isCuriosityIncluded(plan) {
  return get(plan, 'includesLive', false);
}

/**
 * Checks if plan is a student plan
 * @returns {boolean} True if plan has the student cohort
 */
export const isStudentPlan = plan =>
  !!get(plan, 'eligibleCohorts', []).find(
    cohort => cohort.toLowerCase() === cohorts.STUDENT.toLowerCase()
  );

/**
 * Checks if plan is a prepaid plan
 * @returns {boolean} True if plan has isPrepaid plan set to true
 */
export function isPrepaid(plan) {
  return get(plan, 'isPrepaid', false);
}

/**
 * Checks if plan includes Disney Bundle
 * @returns {boolean} True if plan has disneySuperBundle property set to true
 */
export function getIsDisneySuperBundle(plan) {
  return get(plan, 'disneySuperBundle', false);
}

/**
 * Checks if plan includes NOAH component
 * @returns {boolean} True if plan plan includes NOAH in componentIdentifiers
 */
export function getIsNOAHIncluded(plan) {
  const componentIdentifiers = get(plan, 'componentIdentifiers', []);

  return componentIdentifiers.includes(NOAH);
}

/**
 * Checks if plan is SASH Disney bundle
 * @returns {boolean} True if is SASH Disney bundle
 */
export function isSashDisneyBundle(identifier) {
  return [ADS_HULU_DISNEY_ESPN].includes(identifier);
}

/**
 * Checks if plan is No Ads Disney bundle
 * @returns {boolean} True if is NOAH Disney bundle
 */
export function isNoAdsDisneyBundle(identifier) {
  return [NOADS_HULU_DISNEY_ESPN].includes(identifier);
}

/**
 * Checks if plan is SASH LIVE Disney bundle
 * @returns {boolean} True if is LIVE Disney bundle
 */
export function isSashLiveDisneyBundle(identifier) {
  return [ADS_LIVE_DISNEY_ESPN].includes(identifier);
}

/**
 * Checks if plan is Max Bundle with or without ads
 * @returns {boolean} True if is a Max Bundle
 */
export function isMaxBundle(identifier) {
  return [ADS_MAX_BUNDLE, NOADS_MAX_BUNDLE].includes(identifier);
}

/**
 * Checks if plan is Duo Bundle
 * @returns {boolean} True if it is a Duo Bundle
 */
export function isDuoBundle(identifier) {
  return [DUO_BASIC, DUO_PREMIUM].includes(identifier);
}

/**
 * Checks if plan is the Pseudo 2P Bundle (Duo Basic)
 * @returns {boolean} True if is LIVE Disney bundle
 */
export function isPseudo2PBundle(identifier) {
  return [PSEUDO_2P].includes(identifier);
}

export function isTrioBasic(identifier) {
  return [ADS_HULU_DISNEY_ESPN].includes(identifier);
}

/**
 * Finds the badge type for the given plan, it will affect the style of the
 * badge when applicable.
 * @param plan {Plan}
 * @param isUserTypeBadge {boolean} true if CAT_345 experiment is assigned
 * @param isDesktop {boolean} true if the view is desktop
 * @returns {string}
 */
export const getBadgeType = (plan, isUserTypeBadge, isDesktop, show3UpView) => {
  const badgeUserTypePlans = [NOAH, LIVE];

  const badgePlans = [PSEUDO_2P, ADS_HULU_DISNEY_ESPN, NOADS_HULU_DISNEY_ESPN];

  if (
    (plan.isDisneySuperBundle ||
      (show3UpView && badgePlans.includes(plan.identifier))) &&
    !isDesktop
  ) {
    return BADGE_TYPES.LIGHT_GRAY;
  }

  if (isUserTypeBadge && badgeUserTypePlans.includes(plan.identifier)) {
    return BADGE_TYPES.USER_TYPE;
  }

  return BADGE_TYPES.GREEN;
};

/**
 * When in the Live-Only flow, if the given plan does not have a trial available
 * we show the text "Pay Today" to fill up the space.
 * When on the SASH Live Bundle, show New Live callout message.
 * On all other plans, we should either show the displayTrial message, or return null.
 *
 * @param plan The plan
 * @param isLiveOnly {boolean} Whether the user is in the Live-Only flow
 * @param isHuluFreeTrialsEnabled {boolean} Whether the user has the Hulu Free Trials
 * Feature flag enabled
 * @returns {string|null} Plan offer to be displayed to the user
 */
export const getPlanDisplayTrial = (
  plan,
  isLiveOnly,
  isHuluFreeTrialsEnabled
) => {
  const { displayTrial, identifier } = plan;

  if (isSashLiveDisneyBundle(identifier) && !isHuluFreeTrialsEnabled) {
    return LIVE_CALLOUT;
  }

  if (isLiveOnly && !displayTrial) {
    return null;
  }

  return displayTrial && `${displayTrial}, Then`;
};

/**
 * Generates array lists of features to be shown with the plan's card when
 * applicable.
 * @param featureListConfig {*} Site-config's node of copies for feature lists
 * @param plan {Plan}
 * @returns {{positivePlanFeatureList: any[], negativePlanFeatureList: any[]}}
 */
export const getFeatureLists = (featureListConfig, plan) => {
  const hasTrial = get(plan, 'trial.length', 0) > 0;
  const featureList = get(featureListConfig, plan.identifier.toLowerCase(), {});

  let positivePlanFeatureList = get(
    featureList,
    'positive',
    plan.featureList || []
  );
  const negativePlanFeatureList = get(featureList, 'negative', []);

  if (isCuriosityIncluded(plan) && hasTrial) {
    positivePlanFeatureList = [
      ...new Set(positivePlanFeatureList).add(plan.displayTrial),
    ];
  }

  return { positivePlanFeatureList, negativePlanFeatureList };
};

/**
 * Generates alternative text for displaying in plan offer pricing.
 * @param plan {Plan}
 * @param isPriceZero {boolean} true if CAT_321 experiment is assigned
 * @param isHuluFreeTrialsEnabled {boolean} true if Hulu Free Trials feature flag is enabled
 * @returns {string}
 */
export const makeAltTrialText = (
  plan,
  isPriceZero,
  isHuluFreeTrialsEnabled
) => {
  const planUnit = get(plan, 'trial.unit');
  const supportedUnits = ['month', 'week'];

  // CAT-321: If this treatment is winner, update text for plan.displayTrial
  if (isPriceZero) {
    if (supportedUnits.includes(planUnit)) {
      return `Try for $0 for the first ${planUnit}`;
    }
    return plan.display;
  }

  if (isHuluFreeTrialsEnabled) {
    return plan.displayTrial;
  }

  if (supportedUnits.includes(planUnit)) {
    const unitValue = planUnit === 'month' ? '30' : '7';
    return `Get ${plan.name} ${unitValue} days free`;
  }

  return plan.display;
};

export const getPlanDiscountDescription = (
  plan,
  showLiveTvTrial,
  liveTvTrialDiscountDescription
) => {
  if (showLiveTvTrial) {
    return (
      liveTvTrialDiscountDescription ||
      'for 3 mos., then regular monthly price*'
    );
  }

  return get(plan, 'displayDiscount.priceAfterDiscount');
};

/**
 * Generates text for displaying a discount within the plan.displayDiscount object.
 * Used on Mobile plan card for Flash Sale
 * @param plan {Plan}
 * @returns {string}
 */
export const makeDiscountText = plan => {
  const discount = get(plan, 'displayDiscount.discountWithFrequency', null);
  return discount && `Get ${plan.name} for ${discount}`;
};

/**
 * Returns boolean to show if a plan should show the LiveTV Promo text.
 * Based on the LiveTV Promo FF and if the plan is the SASH live TV bundle.
 * @param isLivePromoFeatureOn {boolean}
 * @param plan {Plan}
 * @return {boolean}
 */
export const getShouldShowLiveTVTrial = (isLivePromoFeatureOn, plan) => {
  const planHasLiveTrial =
    plan.displayDiscount?.discount?.name === 'FlashSale Discount';
  return (
    isLivePromoFeatureOn &&
    isSashLiveDisneyBundle(plan.identifier) &&
    planHasLiveTrial
  );
};
